import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext, GlobalContextProvider } from '../script/Values';
import '../styles/Infobar.css';

const Infobar: React.FC = () => {
    const context: GlobalContextProvider = useContext(GlobalContext);

    useEffect(() => {
        if (context.dev) console.log('Rendering Infobar');
    });

    return (
        <footer id='infobar'>
            <div className='footer-section'>
                <h3>{context.translate('footer.title.contact')}</h3>
                <ul>
                    <li>
                        <div className='footer-line'>
                            <i className='fas fa-map-marker-alt' />
                            <p> {context.translate('footer.info.address')} : </p>
                            <a href='https://maps.app.goo.gl/Y4Ds6c3uUZM1t8gLA' target='_blank' rel='noreferrer'>{context.translate('footer.info.address.content')}</a>
                        </div>
                    </li>
                    <li>
                        <div className='footer-line'>
                            <i className='fas fa-envelope' />
                            <p> {context.translate('footer.info.email')} : </p>
                            <a href='mailto:cia.polytech@gmail.com' target='_blank' rel='noreferrer'>{context.translate('footer.info.email.content')}</a>
                        </div>
                    </li>
                    <li>
                        <div className='footer-line'>
                            <i className='fas fa-phone' />
                            <p> {context.translate('footer.info.phone')} : </p>
                            <a href='tel:+33000000000' target='_blank' rel='noreferrer'>{context.translate('footer.info.phone.content')}</a>
                        </div>
                    </li>
                </ul>
            </div>
            <div className='footer-section'>
                <h3>{context.translate('footer.title.links')}</h3>
                <ul>
                    <li>
                        <Link to='/' className='footer-line'>
                            <i className='fas fa-home' />
                            <p> {context.translate('page.home')}</p>
                        </Link>
                    </li>
                    <li>
                        <Link to='/code' className='footer-line'>
                            <i className='fas fa-code' />
                            <p> {context.translate('page.code')}</p>
                        </Link>
                    </li>
                    <li>
                        <Link to='/news' className='footer-line'>
                            <i className='fas fa-newspaper' />
                            <p> {context.translate('page.news')}</p>
                        </Link>
                    </li>
                    <li>
                        <Link to='/contacts' className='footer-line'>
                            <i className='fas fa-address-book' />
                            <p> {context.translate('page.contacts')}</p>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className='footer-section'>
                <h3>{context.translate('footer.title.follow')}</h3>
                <ul>
                    <li>
                        <a href='https://discord.gg/S8gRM95wqw' target='_blank' rel='noreferrer' className='footer-line'><i className='fab fa-discord' /> {context.translate('footer.social.discord')}</a>
                    </li>
                    <li>
                        <a href='https://github.com/CIA-PoPS' target='_blank' rel='noreferrer' className='footer-line'><i className='fab fa-github' /> {context.translate('footer.social.github')}</a>
                    </li>
                    <li>
                        <a href='https://www.facebook.com/profile.php?id=61555761136479' target='_blank' rel='noreferrer' className='footer-line'><i className='fab fa-facebook' /> {context.translate('footer.social.facebook')}</a>
                    </li>
                    <li>
                        <a href='https://www.instagram.com/cia_polytech_paris_saclay/' target='_blank' rel='noreferrer' className='footer-line'><i className='fab fa-instagram' /> {context.translate('footer.social.instagram')}</a>
                    </li>
                </ul>
            </div>
        </footer>
    );
};

export default Infobar;
