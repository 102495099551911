import { createContext } from 'react';

export const BackURL = 'https://api.mes4game.com' as const;

export const DefaultLanguage = 'EN' as const;
export const ValidLanguages = [DefaultLanguage, 'FR'] as const;
export type LanguageKey = typeof ValidLanguages[number];

export type GlobalContextProvider = {
    modeTheme: boolean;
    modeLang: boolean;
    prefLang: string;
    translation: { [key: string]: string };
    translate: (key: string) => string;
    token: string;
    dev: boolean;
    setModeTheme: (theme: boolean) => void;
    setModeLang: (lang: boolean) => void;
    setPrefLang: (lang: LanguageKey) => void;
    setTranslation: (translation: { [key: string]: string }) => void;
    setToken: (token: string) => void;
}

export const GlobalContext = createContext<GlobalContextProvider>({} as GlobalContextProvider);
