// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#hud {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    z-index: 999;
    color: rgb(var(--hud-color));
}

.hud-button {
    background-color: rgb(var(--color-background-four));
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    font-size: 24px;
    font-weight: bold;

    &:hover {
        background-color: rgb(var(--color-background-two));
        transform: scale(1.1);
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/HUD.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,QAAQ;IACR,YAAY;IACZ,4BAA4B;AAChC;;AAEA;IACI,mDAAmD;IACnD,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,iBAAiB;;IAEjB;QACI,kDAAkD;QAClD,qBAAqB;IACzB;AACJ","sourcesContent":["#hud {\n    position: fixed;\n    bottom: 10px;\n    right: 10px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 5px;\n    z-index: 999;\n    color: rgb(var(--hud-color));\n}\n\n.hud-button {\n    background-color: rgb(var(--color-background-four));\n    border-radius: 50%;\n    width: 50px;\n    height: 50px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    transition: 0.3s;\n    font-size: 24px;\n    font-weight: bold;\n\n    &:hover {\n        background-color: rgb(var(--color-background-two));\n        transform: scale(1.1);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
