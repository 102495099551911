// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#infobar {
    background-color: rgb(var(--color-background-four));
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 20px 0;
    width: 100%;
    color: rgb(var(--infobar-color-base));

    & a:hover {
        color: rgb(var(--infobar-color-hover));
    }
}

.footer-section {
    text-align: center;
    width: 25%;
    min-width: 200px;

    & > h3 {
        margin-bottom: 20px;
        font-size: 20px;
    }

    & > ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        list-style-type: none;
    }
}

.footer-line {
    display: inline;

    & * {
        display: inline;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Infobar.css"],"names":[],"mappings":"AAAA;IACI,mDAAmD;IACnD,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,eAAe;IACf,WAAW;IACX,qCAAqC;;IAErC;QACI,sCAAsC;IAC1C;AACJ;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,gBAAgB;;IAEhB;QACI,mBAAmB;QACnB,eAAe;IACnB;;IAEA;QACI,aAAa;QACb,sBAAsB;QACtB,mBAAmB;QACnB,SAAS;QACT,qBAAqB;IACzB;AACJ;;AAEA;IACI,eAAe;;IAEf;QACI,eAAe;IACnB;AACJ","sourcesContent":["#infobar {\n    background-color: rgb(var(--color-background-four));\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-items: center;\n    gap: 30px;\n    padding: 20px 0;\n    width: 100%;\n    color: rgb(var(--infobar-color-base));\n\n    & a:hover {\n        color: rgb(var(--infobar-color-hover));\n    }\n}\n\n.footer-section {\n    text-align: center;\n    width: 25%;\n    min-width: 200px;\n\n    & > h3 {\n        margin-bottom: 20px;\n        font-size: 20px;\n    }\n\n    & > ul {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        gap: 10px;\n        list-style-type: none;\n    }\n}\n\n.footer-line {\n    display: inline;\n\n    & * {\n        display: inline;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
