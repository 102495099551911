import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { DefaultLanguage, ValidLanguages, LanguageKey, GlobalContextProvider, GlobalContext } from './script/Values';
import Loading from './components/Loading';
import Navbar from './components/Navbar';
import Infobar from './components/Infobar';
import HUD from './components/HUD';
import './styles/General.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Home = lazy(() => import('./components/pages/Home'));
const Code = lazy(() => import('./components/pages/Code'));
const Event = lazy(() => import('./components/pages/Event'));
const News = lazy(() => import('./components/pages/News'));
const Read = lazy(() => import('./components/pages/Read'));
const Contacts = lazy(() => import('./components/pages/Contacts'));
const Connect = lazy(() => import('./components/pages/Connect'));
const Review = lazy(() => import('./components/pages/Review'));

const App: React.FC = () => {
    const [__modeTheme, __setModeTheme] = useState<boolean>(localStorage.getItem('modeTheme') ? true : false);
    const [__modeLang, __setModeLang] = useState<boolean>(localStorage.getItem('modeLang') ? true : false);
    const [__prefLang, __setPrefLang] = useState<string>(localStorage.getItem('prefLang') || 'FR');
    const [__translation, __setTranslation] = useState<{ [key: string]: string }>({});
    const [__token, __setToken] = useState<string>(sessionStorage.getItem('token') || '');

    const context: GlobalContextProvider = {
        modeTheme: __modeTheme,
        modeLang: __modeLang,
        prefLang: __prefLang,
        translation: __translation,
        translate: (key: string) => context.translation[key] || key.split('.').pop() || key,
        token: __token,
        dev: true,
        setModeTheme: __setModeTheme,
        setModeLang: __setModeLang,
        setPrefLang: __setPrefLang,
        setTranslation: __setTranslation,
        setToken: __setToken
    };

    useEffect(() => {
        localStorage.setItem('modeTheme', context.modeTheme ? 'true' : '');
    }, [context.modeTheme]);

    useEffect(() => {
        localStorage.setItem('modeLang', context.modeLang ? 'true' : '');
    }, [context.modeLang]);

    useEffect(() => {
        localStorage.setItem('prefLang', context.prefLang);
    }, [context.prefLang]);

    useEffect(() => {
        sessionStorage.setItem('token', context.token);
    }, [context.token]);

    function updateSizes(): void {
        const infobarHeight = document.getElementById('infobar')?.offsetHeight || 0;
        document.documentElement.style.setProperty('--infobar-height', `${infobarHeight}px`);
    };

    useEffect(() => {
        updateSizes();
        window.addEventListener('resize', updateSizes);
        return () => {
            window.removeEventListener('resize', updateSizes);
        }
    });

    async function loadTranslations(lang: string): Promise<{[key: string]: string}> {
        if (!ValidLanguages.includes(lang as LanguageKey))
            throw new Error(`Language ${lang} is not supported.`);
        const translations = await import(`./locales/lang/${lang.toLowerCase()}.json`);
        return translations.default;
    };

    useEffect(() => {
        (async () => {
            context.setTranslation(await loadTranslations(context.modeLang ? context.prefLang : DefaultLanguage));
        })();
        (async () => {
            await new Promise(resolve => setTimeout(resolve, 1));
            updateSizes();
        })();
    }, [context.modeLang, context.prefLang]);

    useEffect(() => {
        if (context.dev) console.log('Rendering App');
    });

    return (
        <GlobalContext.Provider value={context}>
            <Router>
                <input type='checkbox' id='mode-theme' checked={context.modeTheme} readOnly />
                <div id='subroot'>
                    <div id='fixed-background'></div>
                    <Navbar />
                    <main id='main'>
                        <Suspense fallback={<Loading />}>
                            <Routes>
                                <Route path='/' element={<Home />} />
                                <Route path='/code' element={<Code />} />
                                <Route path='/event' element={<Event />} />
                                <Route path='/submit' element={<Review />} />
                                <Route path='/news' element={<News />} />
                                <Route path='/read' element={<Read />} />
                                <Route path='/contacts' element={<Contacts />} />
                                <Route path='/connect' element={<Connect />} />
                            </Routes>
                        </Suspense>
                    </main>
                    <Infobar />
                    <HUD />
                </div>
            </Router>
        </GlobalContext.Provider>
    );
};

export default App;
